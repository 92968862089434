import Vue from 'vue'
import axios from 'axios'

// (window.location.hostname == 'localhost')
const baseURL = (document.URL.indexOf('localhost') !== -1)
    ? 'http://localhost:8000/api/'
    : 'https://webservice.zapermenu.com.br/api/';

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('token')}`;

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response) {
        console.log(error.response)
        if (error.response.status === 429) {
            // showMessage({
            //     title: "Detectamos muitas requisições vindas do seu IP",
            //     content: "Por favor, aguarde e tente novamente em alguns instantes",
            // });
            alert(
                "Detectamos muitas requisições vindas do seu IP" +
                "Por favor, aguarde e tente novamente em alguns instantes"
            )
            return Promise.reject(error);
        }
    
        if (error.response.status === 404) {
            // showMessage({
            //     title: "Página não encontrada",
            //     content: "",
            // });
            alert("Página não encontrada");
            return Promise.reject(error);
        }
  
        if (error.response.status === 422) {
            let errors = '';
            for (var [, value] of Object.entries(error.response.data.errors)) {
                errors += `<span class="errors-item">* ${value}</span><br>`;
                alert(value);
            }

            console.log(errors);

            // showMessage({
            //     title: "Validações",
            //     content: errors,
            //     html: true,
            // });
            return Promise.reject(error);
        }
    
        if (error.response.status === 423) {
            // showMessage({
            //     title: "Este registro não pode ser modificado",
            //     content: error.response.data.message,
            //     html: true,
            // });

            alert("Este registro não pode ser modificado " + error.response.data.message);
            return Promise.reject(error);
        }
    
        if (error.response.status === 500 && error.response.data?.message) {
            // showMessage({
            //     title: "Servidor retornou um problema",
            //     content: error.response.data.message,
            //     html: true,
            //     detail: error.response.data,
            // });

            alert("Servidor retornou um problema " + error.response.data.message);
            return Promise.reject(error);
        }
    
        // showMessage({
        //     title: `Retorno do servidor: ${error.response.status}`,
        //     content: JSON.stringify(error.response.data),
        // });

        alert(
            `Retorno do servidor: ${error.response.status} ` +
            JSON.stringify(error.response.data)
        );
        return Promise.reject(error);
    }
    
    if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);

        // showMessage({
        //     title: "Erro ao efetuar requisição ao servidor",
        //     content: "Por favor, aguarde e tente novamente em alguns instantes",
        //     detail: JSON.stringify(error),
        // });
        alert(
            "Erro ao efetuar requisição ao servidor " +
            JSON.stringify(error)
        );
    } else {
        // Something happened in setting up the request that triggered an Error
        // showMessage({
        //     title: "Erro",
        //     content: error.message,
        // });
        alert("Erro " + error.message);
    }
    console.log(error.config);
    return Promise.reject(error);
});

Vue.prototype.$http = axios.create({ baseURL });
Vue.prototype.$urlAPI = baseURL;
