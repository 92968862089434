import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    empresa: {},
    usuario: {},
  },

  getters: {
    getEmpresa: state => {
      return state.empresa;
    },

    getUsuario: state => {
      return state.usuario;
    },

    getToken: state => {
      return state.usuario.token;
    },
  },

  mutations: {
    setEmpresa(state, payload) {
      state.empresa = payload;
    },

    setUsuario(state, payload) {
      state.usuario = payload;
    },
  },

  actions: {
  },

  modules: {
  },
});
